<template>
  <div>
    <div v-if="!isLoggedIn">
      <p class="title is-4">
        {{ $t('Components.AccountControls.Login.Main_Title') }}
      </p>

      <Message v-if="errorMessage" :message="errorMessage" class="is-danger" />

      <ui-base-input-field
        v-model="emailAddress"
        :labelForInput="false"
        :error="emailErrors"
        :label="$t('Components.AccountControls.Login.Label_Email')"
        @input="$v.emailAddress.$touch()"
        @blur="$v.emailAddress.$touch()"
      />

      <ui-base-input-field
        v-model="password"
        :labelForInput="false"
        :error="passwordErrors"
        fieldType="password"
        :label="$t('Components.AccountControls.Login.Label_Password')"
        @input="$v.password.$touch()"
        @blur="$v.password.$touch()"
      />
      <div class="has-text-right has-padding-top">
        <button
          @click="authorizeUser"
          class="button is-success"
          :class="{ 'is-loading': showSpinner }"
          :disabled="Boolean($v.$anyError) || showSpinner"
        >
          {{ $t('Components.AccountControls.Login.Button_Login') }}
        </button>
      </div>

      <div v-if="true" class="">
        <hr />
        <div class="has-margin-bottom">
          {{ $t('Components.AccountControls.Login.Text_ForgotPassword') }}
          <router-link
            :to="{ name: 'my-account-forgotpassword' }"
            v-html="$t('Components.AccountControls.Login.Link_ForgotPassword')"
          ></router-link>
        </div>
        <div>
          {{ $t('Components.AccountControls.Login.Text_NoAccount') }}
          <router-link
            :to="{ name: 'my-account-register' }"
            v-html="$t('Components.AccountControls.Login.Link_NoAccount')"
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginProvider from '@/providers/login'

import { required, email } from 'vuelidate/lib/validators'
import encryptString from '@/mixins/encryptString'
import { mapGetters, mapActions } from 'vuex'

const touchMap = new WeakMap()
export default {
  name: 'component-login',

  components: {
    Message: () =>
      import(
        /* webpackChunkName: "component-account-controls-login" */ '@/components/UI/Message'
      ),
  },

  mixins: [encryptString],

  data() {
    return {
      emailAddress: '',
      password: '',
      showSpinner: false,
      failCode: false,
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'profileStore/getIsLoggedIn',
    }),

    emailErrors() {
      const errors = []
      if (!this.$v.emailAddress.$dirty) return errors
      if (!this.$v.emailAddress.required) {
        errors.push(
          this.$t('Form.InputErrors.RequiredField', {
            fieldLabel: this.$t('Components.AccountControls.Login.Label_Email'),
          })
        )
      } else if (!this.$v.emailAddress.email) {
        errors.push(this.$t('Form.InputErrors.InvalidEmail'))
      }
      return errors
    },

    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      if (!this.$v.password.required) {
        errors.push(
          this.$t('Form.InputErrors.RequiredField', {
            fieldLabel: this.$t(
              'Components.AccountControls.Login.Label_Password'
            ),
          })
        )
      }
      return errors
    },

    errorMessage() {
      if (this.failCode === 404) {
        return this.$t('Components.AccountControls.Login.Message_Error404')
      } else if (this.failCode === 403) {
        return this.$t('Components.AccountControls.Login.Message_Error403')
      } else if (this.failCode) {
        return this.$t('Components.AccountControls.Login.Message_ErrorUnknown')
      }
      return ''
    },
  },

  /**
   * Form validation rules
   */
  validations: {
    emailAddress: {
      required,
      email,
    },
    password: {
      required,
    },
  },

  methods: {
    ...mapActions('profileStore', ['logoff', 'saveProfileToken']),

    async authorizeUser() {
      let self = this
      if (!this.showSpinner) {
        this.showSpinner = true
        this.failCode = false
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          this.showSpinner = false
        } else {
          let encryptedPassword = self.encryptPassword(self.password)

          // 'PENDING'
          await loginProvider.methods
            .authorizeUser(self.emailAddress, encryptedPassword)
            .then((response) => {
              if (response.status === 200 && response.data.StatusCode === 1) {
                self.$store.dispatch(
                  'profileStore/saveProfileToken',
                  response.data.ProfileToken
                )
              } else {
                if (
                  response.data.StatusCode === -1 ||
                  response.data.StatusCode === -3
                ) {
                  this.failCode = 404
                }

                // Not yet activated
                else if (response.data.StatusCode === -2) {
                  this.failCode = 403
                }
              }
            })
            .catch(() => {
              // //console.log(err)
              // Ensure the failcode is not false at this point
              if (this.failCode === false) {
                this.failCode = true
              }
            })
            .finally(() => {
              self.showSpinner = false
              self = null
            })
        }
      }
    },

    logOff() {
      this.$store.dispatch('profileStore/logoff')
    },
  },
}
</script>
