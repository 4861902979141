<template>
  <PageLayout>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-one-third">
            <div class="has-padding">
              <div class="box">
                <Login />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import { mapGetters } from 'vuex'
const PageLayout = () =>
  import(
    /* webpackChunkName: "my-account-login" */ '@/components/MainSiteElements/Frontend/PageLayout.vue'
  )
const Login = () =>
  import(
    /* webpackChunkName: "my-account-login" */ '@/components/AccountControls/Login'
  )

export default {
  name: 'view-account-login',

  components: {
    Login,
    PageLayout,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'profileStore/getIsLoggedIn',
    }),
  },

  watch: {
    /**
     * Watch isLoggedIn variable
     */
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.$router.push({ name: 'my-account-dashboard' })
      }
    },
  },

  created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'my-account-dashboard' })
    }
  },
}
</script>
